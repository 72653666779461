<template>
  <div class="filter-poseur" :class="{ active: editing }">
    <div v-if="editing">
      <v-popover
        ref="popover"
        popoverClass="popover-Poseur"
        :placement="placement"
        offset="10px"
        :handleResize="true"
      >
        <template slot="popover">
          <div class="Poseur">
            <!-- <b-form-group label="Dépôt assistants maître d'ouvrage :">
              <Multiselect
                v-model="selectedDepot"
                label="text"
                track-by="value"
                :showLabels="false"
                :closeOnSelect="true"
                open-direction="bottom"
                :allow-empty="true"
                @input="changeDepot(selectedDepot)"
                :options="[{ text: 'Aucun', value: 'vide' }, ...depots]"
                :searchable="true"
                :multiple="true"
                :internal-search="true"
                :max-height="600"
                placeholder="Rechercher dépôt"
              >
                <span slot="noResult">Aucun dépôt trouvé.</span>
                <span slot="noOptions">Aucun dépôt trouvé.</span>
              </Multiselect>
            </b-form-group> -->
            <b-form-group label="Assistants maître d'ouvrage :">
              <Multiselect
                v-if="selectedDepot && selectedDepot.length"
                v-model="selectedTechnicianDepots"
                label="text"
                track-by="value"
                :showLabels="false"
                :closeOnSelect="true"
                open-direction="bottom"
                :allow-empty="true"
                :options="technicienDepots"
                :searchable="true"
                :loading="getProjectsUsersPrevisiteurLoading"
                :internal-search="true"
                :max-height="600"
                placeholder="Rechercher Assistants maître d'ouvrage"
                :multiple="true"
                group-values="technicien"
                group-label="depot"
                :group-select="true"
              >
                <span slot="noResult"
                  >Aucun Assistants maître d'ouvrage trouvé.</span
                >
                <span slot="noOptions"
                  >Aucun Assistants maître d'ouvrage trouvé.</span
                >
              </Multiselect>
              <Multiselect
                v-else
                v-model="selectedTechnician"
                label="text"
                track-by="value"
                :showLabels="false"
                :closeOnSelect="true"
                open-direction="bottom"
                :allow-empty="true"
                :options="[
                  {
                    text: 'Aucun',
                    value: 'vide'
                  },
                  ...options
                ]"
                :searchable="true"
                :loading="getProjectsUsersPrevisiteurLoading"
                :internal-search="true"
                :max-height="600"
                placeholder="Rechercher Assistants maître d'ouvrage"
                :multiple="true"
              >
                <span slot="noResult"
                  >Aucun Assistants maître d'ouvrage trouvé.</span
                >
                <span slot="noOptions"
                  >Aucun Assistants maître d'ouvrage trouvé.</span
                >
              </Multiselect>
            </b-form-group>
          </div>
          <div class="action">
            <b-button
              variant="outline-secondary"
              size="sm"
              @click="hundlePopoverCancel()"
            >
              Annuler
            </b-button>
            <b-button variant="success" size="sm" @click="hundlePopoverOk()">
              Enregistrer
            </b-button>
          </div>
        </template>
      </v-popover>
    </div>
    <div
      @click.prevent.stop="editing = true"
      class="icon"
      @click="ouvert"
      :class="{ iconrefactor: styleFilterHiddenCol }"
    >
      <font-awesome-icon class="filter-icon" :icon="['fas', 'filter']" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { VPopover } from 'v-tooltip'
import { mapActions, mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'

Vue.component('v-popover', VPopover)

export default {
  components: {
    Multiselect
  },
  props: {
    placement: {
      type: String,
      default: 'auto'
    },
    styleFilterHiddenCol: { required: false }
  },
  data() {
    return {
      selectedTechnician: [],
      selectedTechnicianDepots: [],
      selectedDepot: [],
      technicienDepots: [],
      editing: false
    }
  },
  methods: {
    ...mapActions([
      'fetchProjectPrevisiteur',
      'fetchProjectDepot',
      'fetchProjectDepotTechnicien'
    ]),
    hundlePopoverCancel() {
      this.$refs['popover'].hide()
      this.selectedTechnician = []
      this.selectedTechnicianDepots = []
      this.selectedDepot = []
      this.technicienDepots = []
      this.editing = false
    },
    async changeDepot(depot) {
      if (depot && depot.length) {
        this.selectedTechnician = []
        this.selectedTechnicianDepots = []
        this.technicienDepots = []
        for (let index = 0; index < depot.length; index++) {
          const value = depot[index]
          if (value.value != null && value.value != 'vide') {
            await this.fetchProjectDepotTechnicien(value)
            if (this.getProjectsDepotsTechnicien) {
              let payloadDepots = {
                depot: value.text,
                technicien: this.getProjectsDepotsTechnicien[value.text]
              }
              if (this.technicienDepots && this.technicienDepots.length) {
                for (
                  let index = 0;
                  index < this.technicienDepots.length;
                  index++
                ) {
                  const depot = this.technicienDepots[index]
                  if (depot.technicien != payloadDepots.technicien) {
                    this.technicienDepots.push(payloadDepots)
                  }
                }
              } else {
                this.technicienDepots.push(payloadDepots)
              }
            }
          }
        }
      }
    },
    async hundlePopoverOk() {
      let payload = []
      if (this.selectedDepot && this.selectedDepot.length) {
        payload.push({
          glue:
            this.selectedDepot && this.selectedDepot.length > 1 ? 'or' : 'and',
          key: 'depot',
          value: this.selectedDepot.map(id => {
            return id.value
          }),
          operateur: '=',
          keyLabel: " Dépôt Assistants maître d'ouvrage",
          valueLabel: this.selectedDepot
            .map(label => {
              return label.text
            })
            .join(' , '),
          keyField: 'depot'
        })
      } else {
        payload.push({
          key: 'depot',
          value: null,
          operateur: '='
        })
      }
      if (
        this.selectedTechnicianDepots &&
        this.selectedTechnicianDepots.length
      ) {
        payload.push({
          glue:
            this.selectedTechnicianDepots &&
            this.selectedTechnicianDepots.length > 1
              ? 'or'
              : 'and',
          key: 'commercial_itinerant',
          value: this.selectedTechnicianDepots.map(id => {
            return id.value
          }),
          operateur: '=',
          keyLabel: "Assistants maître d'ouvrage",
          valueLabel: this.selectedTechnicianDepots
            .map(label => {
              return label.text
            })
            .join(' , '),
          keyField: 'depot_techicien'
        })
      } else {
        payload.push({
          key: 'commercial_itinerant',
          value: null,
          operateur: '='
        })
      }
      if (this.selectedTechnician && this.selectedTechnician.length) {
        payload.push({
          glue:
            this.selectedTechnician && this.selectedTechnician.length > 1
              ? 'or'
              : 'and',
          key: 'commercial_itinerant',
          value: this.selectedTechnician.map(id => {
            return id.value
          }),
          operateur: '=',
          keyLabel: "Assistants maître d'ouvrage",
          valueLabel: this.selectedTechnician
            .map(label => {
              return label.text
            })
            .join(' , '),
          keyField: 'techicien'
        })
      } else {
        payload.push({
          key: 'commercial_itinerant',
          value: null,
          operateur: '='
        })
      }
      if (
        this.selectedDepot &&
        this.selectedDepot.length &&
        this.selectedTechnicianDepots &&
        this.selectedTechnicianDepots.length
      ) {
        payload = payload.filter(p => {
          return p.keyField == 'depot_techicien'
        })
        payload = [
          ...payload,
          {
            key: 'depot',
            value: null,
            operateur: '='
          }
        ]
      }
      this.$emit('change', payload)
      this.hundlePopoverCancel()
    },
    ouvert() {
      this.$nextTick(() => {
        this.$refs['popover'].show()
      })
    }
  },
  computed: {
    ...mapGetters([
      'getProjectsUsersPrevisiteur',
      'getProjectsUsersPrevisiteurLoading',
      'getProjectsDepots',
      'getProjectsDepotsTechnicien',
      'getProjectsDepotsProcessing'
    ]),
    options: function() {
      if (
        this.getProjectsUsersPrevisiteur &&
        (!this.selectedDepot || !this.selectedDepot.length)
      ) {
        return this.getProjectsUsersPrevisiteur
          .map(u => {
            return {
              text: u.full_name,
              value: u.id
            }
          })
          .sort(function compare(a, b) {
            if (a.text < b.text) return -1
            if (a.text > b.text) return 1
            return 0
          })
      }
      return []
    },
    depots: function() {
      return this.getProjectsDepots.map(u => {
        return {
          text: u.name,
          value: u.id
        }
      })
    }
  },
  watch: {
    editing(value) {
      if (value) {
        if (
          (!this.getProjectsUsersPrevisiteur ||
            !this.getProjectsUsersPrevisiteur.length) &&
          !this.getProjectsUsersPrevisiteurLoading
        ) {
          this.fetchProjectPrevisiteur()
        }

        // if (
        //   (!this.getProjectsDepots || !this.getProjectsDepots.length) &&
        //   !this.getProjectsDepotsProcessing
        // ) {
        //   this.fetchProjectDepot({
        //     exclude: [
        //       'accessedByUsers',
        //       'commercialsIte',
        //       'departments',
        //       'editibleByUsers',
        //       'poseurs',
        //       'team',
        //       'visibleByUsers'
        //     ]
        //   })
        // }
        this.$nextTick(() => {
          this.$refs['popover'].show()
        })
      }
    }
  }
}
</script>

<style lang="scss">
.tooltip {
  &.popover-Poseur {
    opacity: 1;
    display: block !important;
    z-index: 2000;

    .tooltip-inner {
      background: black;
      color: white;
      border-radius: 16px;
      padding: 20px 0 0;
      text-align: left;
      max-width: unset;
      width: 300px;

      .close-tooltip {
        position: absolute;
        top: 3px;
        right: 8px;
        color: #656565;
        cursor: pointer;
        &:hover {
          color: black;
        }
      }
      .Poseur {
        .form-group {
          margin: 0 16px 10px;
        }
      }

      .stages {
        margin: 0 16px 10px;
        .label {
          padding-bottom: calc(0.375rem + 1px);
          margin-bottom: 0;
          font-size: inherit;
          line-height: 1.5;
          color: #656565;
        }

        .options {
          display: table;
          width: 100%;
          overflow: hidden;
          margin-bottom: 4px;
          border-radius: 4px;
          table-layout: fixed;
          .stage {
            height: 28px;
            cursor: pointer;
            display: table-cell;
            text-align: center;
            padding: 0;
            font-size: 12px;
            font-weight: 700;
            color: #fff;
            background: #08a742;
            position: relative;

            &:before {
              width: 20px;
              height: 24px;
              position: absolute;
              right: -11px;
              top: 2px;
              border-top: 3px solid #fff;
              border-right: 3px solid #fff;
              transform: scaleX(0.3) rotate(45deg);
              content: ' ';
              background: #08a742;
              cursor: pointer;
              z-index: 1;
            }

            input {
              width: auto;
              position: absolute;
              opacity: 0;
              cursor: pointer;
              border: none;
              padding: 0;
              box-shadow: none;
              margin-right: 3px;
            }

            &.active {
              background: #08a742;
            }
            &.active ~ label {
              background: #e5e5e5;
            }

            &.active ~ label:before {
              background: #e5e5e5;
            }
          }
        }
      }
      .action {
        margin: 0;
        padding: 8px 16px;
        text-align: right;
        border-top: 1px solid #e5e5e5;
        background-color: #f7f7f7;
        button {
          &:first-child {
            margin-right: 4px;
          }
        }
      }
    }

    .tooltip-arrow {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      border-color: black;
      z-index: 1;

      border-color: black !important;
      background: white !important;
      border-width: 1px !important;
      border-left-color: rgba(0, 0, 0, 0.2) !important;
      border-right-color: rgba(0, 0, 0, 0.2) !important;
      border-top-color: rgba(0, 0, 0, 0.2) !important;
      border-bottom-color: rgba(0, 0, 0, 0.2) !important;

      width: 10px;
      height: 10px;
      z-index: -2;
      transform: rotateZ(45deg);
    }

    &[x-placement^='top'] {
      margin-bottom: 5px;

      .tooltip-arrow {
        border-width: 5px 5px 0 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^='bottom'] {
      margin-top: 5px;

      .tooltip-arrow {
        top: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^='right'] {
      margin-left: 5px;

      .tooltip-arrow {
        left: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[x-placement^='left'] {
      margin-right: 40px;

      .tooltip-arrow {
        right: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &.popover {
      $color: #f9f9f9;
      max-width: unset;
      border-radius: 0.1rem;
      .popover-inner {
        background: $color;
        color: black;
        border-radius: 5px;
        box-shadow: 0 5px 30px rgba(black, 0.1);
      }

      .popover-arrow {
        border-color: $color;
      }
    }

    &[aria-hidden='true'] {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.15s, visibility 0.15s;
    }

    &[aria-hidden='false'] {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.15s;
    }
  }
}
.filter-poseur {
  &.active {
    margin: -9px;
  }
  .icon {
    width: 16px;
    // border: 1px solid #d8d8d8;
    position: absolute;
    left: 86%;
    right: 0%;
    bottom: 15px;
    top: 23px;
    background-color: transparent;
    cursor: pointer;
    &:hover {
      background-color: transparent;
    }
    &.iconrefactor {
      top: 15px;
    }
    .filter-icon {
      color: #c5c5c5;
      font-size: 13px;
      &:hover {
        background-color: transparent;
        color: #5e5e5e;
      }
    }
  }
}
</style>
